import moment from 'moment';
import { mask } from '@/utils/Amount';

export const currentDate = moment(new Date()).format('MM/DD/YYYY');

export const getStartAndEndDateMonth = month => {
    return {
        startDate: month.startOf('month').format('YYYY-MM-DD'),
        endDate: month.endOf('month').format('YYYY-MM-DD'),
    };
};

export const currencyFormatter = value => {
    return value || value === 0 ? mask(value, { prefix: '$' }) : null;
};
