<template>
    <div
        class="status"
        :class="statusClass"
    >
        <icon
            :name="statusIcon"
            class="status__icon"
        />
        <div class="status__text">{{ $t(`ri.reservations.status.${status}`) }}</div>
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { STATUS_GROUP_PENDING, STATUS_GROUP_ACTIVE, STATUS_GROUP_COMPLETED, STATUS_GROUP_CANCELED } from '@/views/rentableItems/constants';

export default {
    components: { Icon },

    props: {
        status: {
            type: String,
            required: true,
        },

        uppercase: {
            type: Boolean,
        },
    },

    computed: {
        statusClass() {
            const classes = [];

            if (this.uppercase) {
                classes.push('status--uppercase');
            }

            // prettier-ignore
            switch (this.status) {
            case STATUS_GROUP_PENDING:
                classes.push('status--pending');
                break;
            case STATUS_GROUP_ACTIVE:
                classes.push('status--active');
                break;
            case STATUS_GROUP_COMPLETED:
                classes.push('status--completed');
                break;
            case STATUS_GROUP_CANCELED:
                classes.push('status--canceled');
                break;
            }

            return classes;
        },

        // prettier-ignore
        statusIcon() {
            switch (this.status) {
            case STATUS_GROUP_PENDING:
                return 'clock';
            case STATUS_GROUP_ACTIVE:
                return 'checkmark_2';
            case STATUS_GROUP_COMPLETED:
                return 'clock_2';
            case STATUS_GROUP_CANCELED:
                return 'cancel';
            }

            return '';
        },
    },
};
</script>

<style scoped>
.status {
    @apply flex items-center mt-1;
}

.status__text {
    @apply text-xl font-sofia font-medium;
}

.status__icon {
    @apply w-4 h-4 mr-1 relative;
    top: 2px;
}

.status--uppercase .status__text {
    @apply text-2xs uppercase font-ffdin font-bold;
}

.status--uppercase .status__icon {
    @apply w-3 h-3;
    top: -2px;
}

.status--active,
.status--pending {
    @apply text-purple-600;
}

.status--completed {
    color: #959595;
}

.status--canceled {
    @apply text-red-600;
}
</style>
