import { mask } from '@/utils/Amount';
import { i18n } from '@/i18n';

export function currencyFormatter(value) {
    return value || value === 0 ? mask(value, { prefix: '$' }) : null;
}

export const getTimeslotLabel = (count = 1, riTimeslot) => {
    return i18n.global.t(`ri.periods.${riTimeslot.toLowerCase()}`, count);
};
