<template>
    <Page
        v-if="reservation"
        :title="$t('ri.reservations.details.title')"
    >
        <template v-slot:default>
            <div class="flex justify-between">
                <h2 class="title">{{ reservation.ri.name }}</h2>
                <ReservationStatus :status="reservation.status" />
            </div>

            <hr class="mb-8" />

            <div class="flex mb-16">
                <div class="w-1/2 mr-4">
                    <div class="mb-6">
                        <div class="label">{{ $t('ri.reservations.fields.start_date') }}</div>
                        <div>
                            <div>{{ getFormattedDate(reservation.start) }}</div>
                            <div>{{ getFormattedTime(reservation.start) }}</div>
                        </div>
                    </div>
                    <div class="mb-6">
                        <label class="label">{{ $t('ri.reservations.fields.duration') }}</label>
                        <div>
                            {{ durationLabel }}
                        </div>
                    </div>
                    <div class="mb-6">
                        <label class="label">{{ $t('ri.deposit.price_per', { label: riItemsLabel }) }}:</label>
                        <span>
                            {{ currencyFormatter(reservation.ri.timeslot.rate) }}
                        </span>
                    </div>
                    <div class="mb-6">
                        <div class="label">{{ $t('ri.reservations.fields.created_by') }}</div>
                        <div>
                            <div>{{ reservation.createdBy }}</div>
                            <div>{{ getFormattedDate(reservation.createdAt) }}</div>
                            <div>{{ getFormattedTime(reservation.createdAt) }}</div>
                        </div>
                    </div>
                </div>

                <div class="w-1/2">
                    <div class="mb-6">
                        <div class="label">{{ $t('ri.reservations.fields.end_date') }}</div>
                        <div>
                            <div>{{ getFormattedDate(reservation.end) }}</div>
                            <div>{{ getFormattedTime(reservation.end) }}</div>
                        </div>
                    </div>
                    <div class="mb-6">
                        <label class="label">{{ $t('ri.reservations.fields.number_of_ri', { unit: $t('ri.item', 2) }) }}:</label>
                        <div>
                            {{ quantityLabel }}
                        </div>
                    </div>

                    <div class="mb-6">
                        <label class="label">{{ $t('ri.deposit.deposit') }}:</label>
                        <div>
                            {{ currencyFormatter(reservation.deposit.amount) }}
                        </div>
                    </div>

                    <div v-if="hasCanceledStatus">
                        <div class="label">{{ $t('ri.reservations.fields.canceled_by') }}</div>
                        <div>
                            <div>{{ reservation.updatedBy }}</div>
                            <div>{{ getFormattedDate(reservation.updatedAt) }}</div>
                            <div>{{ getFormattedTime(reservation.updatedAt) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="p-6 bg-gray-150 text-sm">
                <div class="flex justify-between">
                    <label class="label">{{ totalLabel }}:</label>
                    <span>
                        {{ currencyFormatter(reservation.ri.timeslot.rate) }}
                    </span>
                </div>
                <div class="flex justify-between">
                    <label class="label">{{ $t('ri.deposit.deposit') }}:</label>
                    <span>
                        {{ currencyFormatter(reservation.deposit.amount) }}
                    </span>
                </div>

                <div class="flex justify-between mb-3 text-xl">
                    <span>{{ $t('ri.reservations.create.to_be_paid') }}</span>
                    <span>{{ totalValue }}</span>
                </div>

                <button
                    v-if="hasPermission('RP_RESERVATIONS_EDIT') && !hasCanceledStatus"
                    class="btn btn-danger btn-outlined w-full"
                    type="button"
                    @click="cancelReservation"
                >
                    {{ $t('ri.reservations.confirmation.cancel.action') }}
                </button>
            </div>

            <MessageBox
                v-if="hasCanceledStatus && cancellationInfo"
                leadingIcon="info"
            >{{ cancellationInfo }}</MessageBox
            >
        </template>
    </Page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useI18n } from 'vue-i18n';
import Page from '@/components/ui/Page';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import CommunityTimezoneMixin from '@/mixins/CommunityTimezoneMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mask } from '@/utils/Amount';
import ReservationStatus from '@/components/ri/ReservationStatus';
import { STATUS_GROUP_CANCELED, cancelTypes } from '@/views/rentableItems/constants';
import MessageBox from '@/components/ui/MessageBox';
import { currencyFormatter } from '@/utils/helpers';
import { getTimeslotLabel } from '@/views/rentableItems/functions';

export default {
    components: { Page, ReservationStatus, MessageBox },

    mixins: [ConfirmationMixin, CommunityTimezoneMixin, NotifyMixin],

    setup() {
        const { t } = useI18n();

        return { t };
    },

    data: () => {
        return {
            reservation: null,
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'ri/hasPermission',
        }),

        durationLabel() {
            const duration = this.reservation.price.duration;
            const durationLabel = mask(duration, {
                precision: 0,
            });
            const timeslotLabel = getTimeslotLabel(duration, this.reservation.ri.timeslot.unit);

            return `${durationLabel} ${timeslotLabel}`;
        },

        quantityLabel() {
            const qty = this.reservation.price.qty;

            const qtyLabel = mask(qty, { precision: 0 });
            return qtyLabel;
        },

        totalLabel() {
            return `${this.quantityLabel} ${this.riItemsLabel} х ${this.durationLabel}`;
        },

        hasLocationChain() {
            const locationsChain = this.reservation?.ri.locationsChain;

            return Array.isArray(locationsChain) && locationsChain.length;
        },

        timeslotRate() {
            return mask(this.reservation.ri.timeslot.rate, { prefix: '$' });
        },

        entireCost() {
            return mask(this.reservation.price.cost, { prefix: '$' });
        },

        hasCanceledStatus() {
            return this.reservation.status === STATUS_GROUP_CANCELED;
        },

        cancellationInfo() {
            const assetsCount = this.reservation.price.qty;

            switch (this.reservation.cancelType) {
            case cancelTypes.ASSET_DELETED:
            case cancelTypes.RI_QTY_DECREASED:
                return this.t('ri.reservations.messages.cancel.item_deleted', {
                    riType: this.t('ri.item', assetsCount),
                    deleted: this.t('ri.reservations.item_deleted', assetsCount),
                });
            case cancelTypes.RI_DELETED:
                return this.t('ri.reservations.messages.cancel.ri_deleted');
            default:
                return '';
            }
        },

        riItemsLabel() {
            return this.t('ri.item', this.reservation.price.qty);
        },

        totalValue() {
            return currencyFormatter(
                (this.reservation.deposit?.amount ?? 0) +
                    this.reservation.ri.timeslot.rate * this.reservation.price.qty * this.reservation.price.duration
            );
        },
    },

    created() {
        this.setReservationChanged(false);
        this.fetchReservation();
    },

    methods: {
        currencyFormatter,

        ...mapActions({
            setReservationChanged: 'ri/setReservationChanged',
        }),

        cancelReservation() {
            this.requestConfirmation({
                text: this.t('ri.reservations.confirmation.cancel.text'),
                cancelCaption: this.t('ri.reservations.confirmation.cancel.cancel_caption'),
                confirmCaption: this.t('ri.reservations.confirmation.cancel.confirm_caption'),
                type: 'warning',
            }).then(confirmed => {
                if (confirmed) {
                    this.loading = true;

                    this.$riDataProvider
                        .cancel('reservations', { data: [this.reservation.id] })
                        .then(updatedReservation => {
                            this.reservation = updatedReservation;
                            this.setReservationChanged(true);
                        })
                        .catch(error => {
                            this.notifyError(error.message);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },

        getFormattedDate(dateTime) {
            return this.formatDateTime(dateTime, 'MM/DD/YYYY');
        },

        getFormattedTime(dateTime) {
            return this.formatDateTime(dateTime, 'hh:mm a');
        },

        fetchReservation() {
            const { reservationId } = this.$route.params;
            this.loading = true;

            this.$riDataProvider
                .getOne('reservations', { id: reservationId })
                .then(reservation => {
                    this.reservation = reservation;
                })
                .catch(error => this.notifyError(error.message))
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.title {
    @apply text-xl font-medium font-sofia text-gray-700 mb-2 lowercase;
}
</style>
