import moment from 'moment-timezone';

export default {
    methods: {
        /**
         * Prepare moment object with community timezone set (if present)
         *
         * @param input can be ISO8601 string, timestamp (milliseconds) or Date object
         * @returns {moment.Moment}
         */
        parseDateTime(input) {
            const momentObj = moment(input);

            const timezone = this.$store?.getters['auth/currentProfile']?.communityTimezone ?? null;

            return timezone ? momentObj.tz(timezone) : momentObj;
        },
        /**
         * Prepare datetime string with respect to community timezone
         *
         * @param input can be ISO8601 string, timestamp (milliseconds) or Date object
         * @param format moment.js formatting rule string
         * @returns {string}
         */
        formatDateTime(input, format = 'MM/DD/YYYY hh:mm a') {
            return this.parseDateTime(input).format(format);
        },
    },
};
