export const STATUS_GROUP_PENDING = 'PENDING';
export const STATUS_GROUP_ACTIVE = 'ACTIVE';
export const STATUS_GROUP_COMPLETED = 'COMPLETED';
export const STATUS_GROUP_CANCELED = 'CANCELED';

export const SORT_BACK = 'BACK';

export const cancelTypes = {
    MANUAL: 'MANUAL',
    RI_DELETED: 'RI_DELETED',
    ASSET_DELETED: 'ASSET_DELETED',
    RI_QTY_DECREASED: 'RI_QTY_DECREASED',
};

export const rentableItemsTabs = {
    ALL_ITEMS: 'ALL_ITEMS',
    MY_RESERVATIONS: 'MY_RESERVATIONS',
};

export const TIMESLOT = {
    HOUR: 'HOUR',
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
};

export const DEPOSIT_FIELD = 'deposit';
